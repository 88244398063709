import React, { useState } from 'react';

import Logo from './components/Logo.jsx';
import HoverDiv from './components/HoverDiv.jsx';

import linkedinIcon from './images/linkedin.png';
import instagramIcon from './images/instagram.png';
import twitterIcon from './images/twitter.png';
import discordIcon from './images/discord.png';
import gitbookIcon from './images/gitbook.png';
import raremindVideo from './raremind.gif';

function App() {
  const sizeIconFooter = '35px';
  const [mouseEnter, setMouseEnter] = useState(false);
  const [mouseEnter2, setMouseEnter2] = useState(false);

  return (
    <div className="flex flex-col m-5">
      <button
        onClick={() => window.open('https://app.raremind.com')}
        className={
          mouseEnter2
            ? 'bg-rblue-300 md:rounded-2xl mb-2 p-3 shadow-lg shadow-blue-500/50 md:hidden block fixed top-0 left-0 right-0 rounded-none'
            : 'bg-rblue-300 md:rounded-2xl mb-2 p-3  md:hidden block fixed top-0 left-0 right-0 rounded-none'
        }
        onMouseLeave={() => setMouseEnter2(false)}
        onMouseEnter={() => setMouseEnter2(true)}
      >
        Connect
      </button>
      <div className="flex md:flex-row flex-col justify-between gap-2">
        <div className="flex flex-col items-center md:gap-12 gap-2 border-rblue-500 rounded-2xl w-full md:w-2/4 md:border-2 p-2 ">
          <div className="flex flex-col items-center text-center md:mt-1 mt-12">
            <Logo />
            <div className="mt-3"> Play chess on any platform and earn rewards </div>
          </div>
          <img className={'md:w-11/12 sm:w-8/12 w-3/4 max-w-md max-h-md	'} src={raremindVideo}></img>
        </div>

        <div className="flex flex-col md:w-2/4 w-full">
          <button
            onClick={() => window.open('https://app.raremind.com')}
            className={
              mouseEnter
                ? 'bg-rblue-300 rounded-2xl mb-2 p-3 duration-300 ease-in-out shadow-[0_4px_88px_35px_rgba(16,98,255,0.5)] md:block hidden '
                : 'bg-rblue-300 rounded-2xl mb-2 p-3 md:block hidden'
            }
            onMouseLeave={() => setMouseEnter(false)}
            onMouseEnter={() => setMouseEnter(true)}
          >
            Connect
          </button>
          <div className="sm:grid sm:grid-cols-2 gap-4 md:gap-2 flex flex-col">
            <HoverDiv
              title={'Gamification'}
              number={'01'}
              onClickURL={'http://app.raremind.com'}
              hoverSentence={
                'Play chess on your favourite platforms (chess.com, lichess.org), and get rewarded depending on your performances.'
              }
              hoverProp={
                'hover:border-[#51F5FF] hover:shadow-[0_4px_102px_15px_rgba(81,245,255,0.5)] hover:text-[#51F5FF]'
              }
              mobileProp={'border-[#51F5FF] shadow-[#51F5FF] text-[#51F5FF]'}
            />
            <HoverDiv
              title={'Leaderboards'}
              number={'02'}
              hoverSentence={'Join worldwide competitions regardless of your level.'}
              hoverProp={
                'hover:border-[#FDF900] hover:shadow-[0_4px_102px_15px_rgba(253,249,0,0.5)] hover:text-[#FDF900]'
              }
              mobileProp={'border-[#FDF900] shadow-[#FDF900] text-[#FDF900]'}
            />
            <HoverDiv
              title={'Challenge anyone'}
              onClickURL={'http://app.raremind.com'}
              number={'03'}
              hoverSentence={
                'Connect with chess players worldwide.'
              }
              hoverProp={
                'hover:border-[#59FF30] hover:shadow-[0_4px_102px_15px_rgba(89,255,48,0.5)]  hover:text-[#59FF30]'
              }
              mobileProp={'border-[#59FF30] shadow-[#59FF30] text-[#59FF30]'}
            />
            <HoverDiv
              title={'Digital Cards'}
              number={'04'}
              hoverSentence={
                'Get access to your favorite community.'
              }
              hoverProp={
                'hover:border-[#EF50B9] hover:shadow-[0_4px_102px_15px_rgba(239,80,185,0.5)]  hover:text-[#EF50B9]'
              }
              mobileProp={'border-[#EF50B9] shadow-[#EF50B9] text-[#EF50B9]'}
            />
          </div>
        </div>
      </div>

      <div className=" flex md:flex-row flex-col justify-between items-center gap-6 md:pt-4 pt-6 px-4">
        <div className="flex flex-row gap-4 justify-center	">
          <a href="https://discord.gg/P6hDRhUFh5" target="_blank" rel="noreferrer">
            <img alt="discord icon" src={discordIcon} height={sizeIconFooter} width={sizeIconFooter} />
          </a>
          <a href="https://www.linkedin.com/company/raremind/" target="_blank" rel="noreferrer">
            <img alt="linkedin icon" src={linkedinIcon} height={sizeIconFooter} width={sizeIconFooter} />
          </a>
          <a href="https://twitter.com/Raremind_xyz" target="_blank" rel="noreferrer">
            <img alt="twitter icon" src={twitterIcon} height={sizeIconFooter} width={sizeIconFooter} />
          </a>
          <a href="https://www.instagram.com/raremind2/" target="_blank" rel="noreferrer">
            <img alt="instagram icon" src={instagramIcon} height={sizeIconFooter} width={sizeIconFooter} />
          </a>
          <a href="https://raremind.gitbook.io/raremind" target="_blank" rel="noreferrer">
            <img alt="gitbook icon" src={gitbookIcon} height={sizeIconFooter} width={sizeIconFooter} />
          </a>
        </div>
        <div className="flex md:flex-row flex-col gap-4">
          <div className="text-sm text-center">
            <a href="https://raremind.gitbook.io/raremind" rel="noreferrer" target="_blank">Whitepaper</a>
          </div>
          <div className="text-sm text-center	"> Privacy Policy </div>
          <div className="text-sm text-center	"> Terms of Use </div>
          <div className="text-sm text-center	"> © Copyright {new Date().getFullYear()} Raremind </div>
        </div>
      </div>
    </div>
  );
}

export default App;
