import React from 'react';

export default function Logo() {
  return (
    <svg className="md:w-40 md:h-20 w-60 h-30 pb-2" viewBox="0 0 582 199" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_129_9957)">
        <path
          d="M140.094 93.3625V95.5143H91.3006C91.3006 94.5724 89.8321 92.2866 88.8961 91.2106C71.9189 71.7059 61.627 63.9023 56.4144 60.8085C47.3247 55.5628 40.3733 52.8709 34.3578 52.603V72.7818C34.3578 91.2106 37.9688 95.5185 37.9688 95.5185H0.936011C0.936011 95.5185 4.40965 91.2148 4.54693 72.7818L4.9463 24.0848C5.07942 2.95983 0 0 0 0H60.5537C113.49 0 124.985 9.95543 124.985 26.3664C124.985 40.7595 109.343 52.5988 87.2862 56.5006C105.732 72.2417 127.788 92.5545 140.09 93.3625H140.094ZM34.3537 44.5273C41.3051 48.5631 50.2617 52.4649 65.3669 52.4649C80.472 52.4649 94.9074 45.7372 94.9074 32.554C94.9074 13.853 76.1913 6.1876 53.8685 6.1876C44.7788 6.1876 39.9656 6.72766 35.4228 7.53146C34.8862 12.3752 34.3537 17.3529 34.3537 25.5584V44.5273Z"
          fill="white"
        ></path>
        <path
          d="M165.659 66.9384C176.718 49.3632 187.645 31.6502 198.705 13.9372C193.244 8.57149 186.712 3.87391 179.916 2.26212V1.58984H227.622L263.335 56.3323L269.866 66.5292C276.663 77.1311 287.458 92.2928 292.919 95.5122H244.682C247.216 92.4264 244.284 85.0481 241.618 79.5446L234.153 67.0678H177.519L168.59 81.9623C165.924 86.7935 164.593 91.3533 166.326 95.5164H143.273C150.871 88.5389 159.529 76.463 165.659 66.9384ZM230.421 60.9004L212.432 31.1115C210.698 28.4266 208.169 24.6727 205.104 20.9146L181.118 60.9004H230.421Z"
          fill="white"
        ></path>
        <path
          d="M439.381 93.3624V95.5143H391.142C391.142 94.5723 389.686 92.2864 388.765 91.2104C371.98 71.7048 361.805 63.9009 356.652 60.807C347.666 55.5611 340.793 52.8691 334.846 52.6011V72.7808C334.846 91.2104 338.416 95.5185 338.416 95.5185H301.808C301.808 95.5185 305.242 91.2146 305.378 72.7808L305.773 24.0816C305.905 2.95996 300.883 0 300.883 0H360.753C413.088 0 424.451 9.95586 424.451 26.3675C424.451 40.7613 408.987 52.6011 387.181 56.5031C405.417 72.2449 427.227 92.5586 439.385 93.3666L439.381 93.3624ZM334.842 44.5293C341.714 48.5652 350.569 52.4671 365.503 52.4671C380.436 52.4671 394.712 45.7392 394.712 32.5554C394.712 13.8536 376.208 6.18787 354.139 6.18787C345.153 6.18787 340.394 6.72795 335.903 7.53179C335.373 12.3757 334.846 17.3537 334.846 25.5595V44.5293H334.842Z"
          fill="white"
        ></path>
        <path
          d="M581.069 61.2307L575.243 95.5164L453.715 94.9808C453.715 94.9808 456.892 90.7127 456.892 72.4323L457.287 24.1384C457.287 5.86206 453.711 1.58984 453.711 1.58984H569.945L575.771 35.477L570.872 36.0126C553.132 6.79622 536.055 7.46051 487.733 7.73038C487.07 11.2013 486.411 16.4035 486.411 24.2754V42.5517H565.179V48.688H486.411V72.4364C486.411 80.1754 487.074 85.3777 487.733 88.8444C540.69 89.1101 557.499 92.0455 576.166 60.6951L581.065 61.2307H581.069Z"
          fill="white"
        ></path>
        <path
          d="M203.771 198.863H165.328C165.46 194.71 164.136 189.081 162.412 182.919L148.758 143.261C146.373 136.831 144.117 131.202 141.864 126.244L112.835 198.73H108.991L104.086 189.886V189.753L77.9735 141.784C74.5289 136.155 71.6124 131.064 68.4278 125.973L52.6531 179.299C51.0607 186 49.3405 193.368 50.1326 198.863L33.4297 198.997C35.9461 194.439 39.2627 185.6 41.383 179.299L61.5305 116.057C58.878 112.576 55.8295 108.956 52.3849 105.07H88.8392C92.0197 112.709 94.9362 118.605 98.3849 125.435L117.604 160.807L137.752 117.262C135.099 112.038 132.715 108.018 130.726 105.07H167.313C169.565 115.791 171.685 122.487 174.47 130.393L186.664 163.087C191.037 174.879 196.606 189.215 203.763 198.863H203.771Z"
          fill="white"
        ></path>
        <path
          d="M249.942 105.07C249.942 105.07 246.216 109.363 246.216 127.882V176.319C246.216 194.7 249.942 198.997 249.942 198.997H211.734C211.734 198.997 215.46 194.704 215.46 176.319L215.872 127.748C215.872 109.367 211.734 105.07 211.734 105.07H249.942Z"
          fill="white"
        ></path>
        <path
          d="M402.767 198.989H359.926C336.645 176.177 305.645 144.38 283.958 123.18C283.825 124.654 283.825 126.266 283.825 127.878V176.315C283.825 194.696 287.816 198.993 287.816 198.993H270.12C270.12 198.993 274.112 194.7 274.112 176.315L274.509 127.744C274.509 121.305 273.975 116.608 273.445 113.255C269.851 109.902 266.791 107.083 264.266 105.07H305.509L384.405 181.284C384.537 179.806 384.537 178.064 384.537 176.319L384.935 127.748C384.935 109.367 380.943 105.07 380.943 105.07H398.238C398.238 105.07 394.246 109.363 394.246 127.882V176.319C394.246 183.564 394.913 188.662 395.576 192.153L402.759 198.997L402.767 198.989Z"
          fill="white"
        ></path>
        <path
          d="M549.229 143.928C549.229 171.931 523.335 198.997 475.549 198.997H418.688C418.688 198.997 422.293 194.577 422.293 176.218L422.692 127.716C422.692 109.361 418.688 105.07 418.688 105.07H483.956C517.594 105.07 549.225 114.719 549.225 143.928H549.229ZM518.529 153.71C518.529 123.696 496.638 111.237 473.011 111.237C467.271 111.104 460.329 111.371 452.99 113.785C452.454 117.133 452.055 121.691 452.055 127.854V176.222C452.055 180.508 452.188 183.994 452.591 186.809C458.863 190.695 466.872 193.376 479.42 193.376C506.516 193.376 518.529 176.626 518.529 153.714V153.71Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_129_9957">
          <rect width="581.07" height="198.996" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
