import React, { useState } from 'react';

export default function HoverDiv({ title, number, hoverSentence, hoverProp, mobileProp, onClickURL }) {
  const [mouseEnter, setMouseEnter] = useState(false);

  return (
    <div onClick={onClickURL ? () => window.open(onClickURL) : ''} className={onClickURL ? 'cursor-pointer' : ''}>
      <div
        className={` duration-300 ease-in-out h-80 w-full md:flex hidden flex-col justify-end items-end border-2 border-rblue-500 rounded-2xl hover:bg-gradient-to-b from-[#24272C] via-[#181818] to-black-500 ${hoverProp}`}
        onMouseLeave={() => setMouseEnter(false)}
        onMouseEnter={() => setMouseEnter(true)}
      >
        <div
          className={`flex md:flex-col flex-col-reverse justify-between w-full h-full px-4 py-2  ${
            mouseEnter ? 'duration-75' : ''
          }`}
        >
          <div className="text-sm">{mouseEnter ? hoverSentence : ''}</div>
          <div className="flex md:flex-row flex-row-reverse justify-between  w-full ">
            <div className="">{number}</div>
            <div className="uppercase">{title}</div>
          </div>
        </div>
        <div className={'flex-col-reverse'}></div>
      </div>
      {/* fullscreen above and phone/tablet below */}
      <div
        className={`h-80 w-full flex flex-col md:hidden justify-center border-2 border-rblue-500 rounded-2xl bg-gradient-to-b from-[#24272C] via-[#181818] to-black-500 ${mobileProp}`}
      >
        <div className={'flex md:flex-col flex-col-reverse justify-between w-full h-full px-4 py-2'}>
          <div className="text-sm">{hoverSentence}</div>
          <div className="flex md:flex-row flex-row-reverse justify-between w-full ">
            <div className="">{number}</div>
            <div className="uppercase">{title}</div>
          </div>
        </div>
        <div className={'flex-col-reverse'}></div>
      </div>
    </div>
  );
}
